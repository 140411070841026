import "./RadioPlayer.scss";

import { Col, Container, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";

import RadioPlayerContext from "./RadioPlayerContext";
import ReactPlayer from "react-player";
import fetchNowPlaying from "../net/fetchNowPlaying";
import type TrackInfo from "../../types/TrackInfo";
import missingArt from "../../public/assets/missing-art.png";

const {
  PINK_AZURECAST_RADIO_URL,
} = import.meta.env as Record<string, string>;

export default function RadioPlayer() {
  const context = useContext(RadioPlayerContext);
  if (!context) {
    throw new Error("useRadioPlayer must be used within a RadioPlayerProvider");
  }

  const [isOpen, setIsOpen] = useState(true);
  const { isPlaying, togglePlay } = context;
  const [isVolumeBarVisible, setVolumeBarVisible] = useState(false);
  const [volumeBarHeight, setVolumeBarHeight] = useState(100);
  const [isMute, setIsMute] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [currentTrack, setCurrentTrack] = useState<TrackInfo | null>(null);
  const streamUrl = PINK_AZURECAST_RADIO_URL;

  useEffect(() => {
    const fetchTrackInfo = () => {
      fetchNowPlaying()
        .then((track) => {
          if (track) {
            setCurrentTrack(track);
          }
        })
        .catch((error) => {
          console.error("Error fetching track info:", error);
        });
    };
    
    fetchTrackInfo();
    const intervalId = setInterval(fetchTrackInfo, 15000); // Fetch track info every 5 seconds

    
    return () => {
      clearInterval(intervalId);
      setIsOpen(true);
      context.stopPlayer();
      setVolumeBarVisible(false);
      setIsMute(false);
      setVolumeBarHeight(100);
    };
  }, [context, context.stopPlayer]);

  // hide/show player effect
  useEffect(() => {
    const player = document.getElementById("player-bar");
    if (!player) return () => {};

    const container = document.getElementById("hide-player");
    if (!container) return () => {};

    container.innerHTML = "";
    const iconElement = document.createElement("span");
    iconElement.className = "qw-hidesstat";
    container.appendChild(iconElement);

    if (isOpen) {
      player.classList.add("open");
      iconElement.classList.add("chevron-down");
    } else {
      player.classList.remove("open");
      iconElement.classList.add("chevron-up");
    }
  }, [isOpen]);

  //start/stop play effect
  useEffect(() => {
    const container = document.getElementById("player-button");
    if (!container) return () => {};

    container.innerHTML = "";
    const iconElement = document.createElement("span");
    iconElement.className = "qw-hidesstat";
    container.appendChild(iconElement);

    if (isPlaying) iconElement.classList.add("zondicons--pause-outline");
    else iconElement.classList.add("zondicons--play-outline");
  }, [isPlaying]);

  //vollume bar expand effect
  useEffect(() => {
    const container = document.getElementById("mute-button");
    if (!container) return () => {};

    container.innerHTML = "";
    const iconElement = document.createElement("span");
    iconElement.className = "qw-hidesstat";
    container.appendChild(iconElement);

    if (isMute)
      iconElement.classList.add("pepicons-pop--music-note-double-circle-off");
    else iconElement.classList.add("pepicons-pop--music-note-double-circle");
  }, [isMute]);

  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleMute = () => setIsMute(!isMute);
  const toggleVolumeBar = () => setVolumeBarVisible(!isVolumeBarVisible);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  // handle volume bar with cursor click
  const handleVolumeClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const container = document.getElementById("volume-cursor");
    if (!container)
      throw new Error("Failed to find react player volume-cursor element");

    const volumeBarHeight = 100;
    const rect = container.getBoundingClientRect();
    const height = event.clientY - (rect.bottom - volumeBarHeight);

    // Ensure height doesn't go below 0 or above 100
    setVolumeBarHeight(Math.min(Math.max(volumeBarHeight - height, 0), 100));

    // Hide volume bar after 2 seconds
    setTimeout(() => {
      setVolumeBarVisible(false);
    }, 2000);
  };

  // Hide volume bar after it loses focus
  const handleVolumeBlur = () => {
    setTimeout(() => {
      setVolumeBarVisible(false);
    }, 2000);
  };

  return (
    <section
      id="player-bar"
      className="player-footer-bar player-animated"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ backgroundColor: isHovered ? "#141414" : "rgba(0, 0, 0, 0.23)" }}
    >
      <Container
        id="hide-player"
        onClick={toggleOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundColor: isHovered ? "#141414" : "rgba(0, 0, 0, 0.23)",
        }}
      />
      <Row className="player-inner">
        <Col sm={1} md={1} xs={1} id="play-button">
          <Container id="player-button" onClick={togglePlay} />
          <ReactPlayer
            url={streamUrl}
            playing={isPlaying}
            volume={volumeBarHeight / 100.0}
            muted={isMute}
            width="100%"
            height="100%"
            onError={(e) => console.error('ReactPlayer Error:', e)}
            onReady={() => console.log('ReactPlayer Ready')}
            onPlay={() => console.log('ReactPlayer Playing')}
            onPause={() => console.log('ReactPlayer Paused')}
          />
        </Col>
        <Col sm={1} md={1} id="cover-image" className="hidden-xs text-right">
          <img src={currentTrack?.art ?? missingArt} />
        </Col>
        <Col sm={2} md={2} xs={2} id="track-info" className="">
          <span
            id="track-title"
            className="qw-hidesstat"
            data-type="tracktitle "
            data-username="pinkradio"
          >
            {currentTrack?.title ?? "Unknown title"}
          </span>
          <span
            id="track-artist"
            className="qw-hidesstat"
            data-type="trackalbum"
            data-username="pinkradio"
          >
            {currentTrack?.artist ?? "Unknown artist"}
          </span>
        </Col>
        <Col
          id="volume-controls"
          sm={2}
          md={2}
          lg={1}
          className="hidden-xs volume-controls"
        >
          <Container id="volume-button" onClick={toggleVolumeBar}>
            <span className="jam--volume-circle"></span>
          </Container>
          <Container
            id="volume-controller"
            className={`animated ${isVolumeBarVisible ? "open" : ""}`}
            onClick={handleVolumeClick}
            onBlur={handleVolumeBlur}
            tabIndex={0}
          >
            <Container
              id="volume-cursor"
              style={{ height: `${volumeBarHeight}%` }}
            ></Container>
          </Container>

          <Container id="mute-button" onClick={toggleMute}></Container>
        </Col>
      </Row>
    </section>
  );
}
